import IHandinDataStrategy from "./IHandinDataStrategy";
import {Handin} from "../widgets/HandinComponent";

export class MockHandinDataStrategy implements IHandinDataStrategy {
    getData(): Handin[] {
        return [{
            name: "Aflevering 2",
            course: "Eksperimentel Systemudvikling",
            isFinished: true,
            isReHandin: false,
        },{
            name: "Handin 2G",
            course: "Beregnelighed & Logik",
            isFinished: false,
            dueDate: new Date("2021-04-01")
        },
        {
            name: "Aflevering 3",
            course: "Eksperimentel Systemudvikling",
            isFinished: false,
            dueDate: new Date("2021-04-11")
        },
        {
            name: "Handin 1G",
            course: "Beregnelighed og Logik",
            isFinished: true,
            isReHandin: true
        }
        ];
    }
}
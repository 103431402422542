import {Setting, settingType} from './WidgetComponent'

let HandinSettings: Array<Setting>;

export enum HandinSettingIndex{
    showZoomLinks,
    showLocations,
    showExtraPdfs,
    showHandins
}

HandinSettings = [
    {name: 'Vis Zoom Links',
        type: settingType.toggle,
        currentValue: true },
    {name: 'Vis lokaler',
        type: settingType.toggle,
        currentValue: true },
    {name: 'Vis extra PDF\'er',
        type: settingType.toggle,
        currentValue: true },
    {name: 'Vis Handins',
        type: settingType.toggle,
        currentValue: false },
]

export default HandinSettings;
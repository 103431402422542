import IScheduleDataStrategy from "./IScheduleDataStrategy";
import {Schedule} from "../widgets/ScheduleComponent";

class MockScheduleDataStrategy implements IScheduleDataStrategy{
    getData(): Schedule {
        return [
            {
              startTime: new Date(),
              endTime: new Date(),
              courseName: "Numerisk Lineær Algebra",
              activity: "Forelæsning",
              zoom: "243234234234",
              location: "5335-016",
              pdf: "http://www.africau.edu/images/default/sample.pdf"
            },
            {
              startTime: new Date(2021, 4, 5, 10, 15),
              endTime: new Date(2021, 4, 5, 12, 0),
              courseName: "Numerisk Lineær Algebra",
              activity: "Forelæsning",
              zoom: "243234234234",
              location: "5335-016"
            },
            {
                startTime: new Date(2021, 4, 5, 12, 15),
                endTime: new Date(2021, 4, 5, 14, 0),
                courseName: "Optimering",
                activity: "Forelæsning",
                zoom: "243234234234",
                location: "5335-016"
            },
            {
                startTime: new Date(2021, 4, 6, 8, 15),
                endTime: new Date(2021, 4, 6, 10, 0),
                courseName: "Numerisk Lineær Algebra",
                activity: "TØ",
                zoom: "243234234234",
                location: "5335-016"
            },
            {
                startTime: new Date(2021, 4, 7, 12, 15),
                endTime: new Date(2021, 4, 7, 14, 0),
                courseName: "Programmeringssprog",
                activity: "Forelæsning",
                zoom: "243234234234",
                location: "5335-016"
            },
            {
                startTime: new Date(2021, 4, 7, 14, 15),
                endTime: new Date(2021, 4, 5, 62, 0),
                courseName: "Programmeringssprog",
                activity: "TØ",
                zoom: "243234234234",
                location: "5335-016"
            },
            {
                startTime: new Date(2021, 4, 8, 10, 15),
                endTime: new Date(2021, 4, 8, 12, 0),
                courseName: "Numerisk Lineær Algebra",
                activity: "Forelæsning",
                zoom: "243234234234",
                location: "5335-016"
            },
            {
                startTime: new Date(2021, 4, 8, 12, 15),
                endTime: new Date(2021, 4, 8, 14, 0),
                courseName: "Optimering",
                activity: "Forelæsning",
                zoom: "243234234234",
                location: "5335-016"
            },
            {
                startTime: new Date(2021, 4, 8, 14, 15),
                endTime: new Date(2021, 4, 8, 17, 0),
                courseName: "Optimering",
                activity: "TØ",
                zoom: "243234234234",
                location: "5335-016"
            },
            {
                startTime: new Date(2021, 4, 9, 10, 15),
                endTime: new Date(2021, 4, 9, 12, 0),
                courseName: "Programmeringssprog",
                activity: "Forelæsning",
                zoom: "243234234234",
                location: "5335-016"
            },
        ];
    }
}

export default MockScheduleDataStrategy;
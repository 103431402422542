import {Component} from 'react';
import './WidgetGrid.css';
import {Responsive, WidthProvider, Layout} from "react-grid-layout";
import WidgetFactory from '../widgets/WidgetFactory';
const ResponsiveGridLayout = WidthProvider(Responsive);

export type WidgetGridEntry = {
    type: string,
    width?: number,
    height?: number,
    x?: number,
    y?: number
};

type WidgetGridProps = {
    factories: WidgetFactory[],
    widgets: Map<string, WidgetGridEntry>,
    layout?: Layout[],
    editMode: boolean,
    onLayoutChange?: (layout: Layout[]) => void,
    onWidgetsChange?: (widgets: Map<string, WidgetGridEntry>) => void
};

type WidgetGridState = {
    factories: Map<string, WidgetFactory>
};

class WidgetGrid extends Component<WidgetGridProps, WidgetGridState> {
    constructor(props: WidgetGridProps) {
        super(props);

        let factories = new Map();
        this.props.factories.forEach(f => {
            factories.set(f.getComponentType().WidgetTypeID, f)
        });

        this.state = {factories: factories};
    }

    onResize(layout: Layout[], oldItem: Layout, newItem: Layout){
        let newWidgets = new Map(this.props.widgets);
        let elem = newWidgets.get(newItem.i);

        if(!elem)
            throw new Error("Could not find widget element key");

        elem.width = newItem.w;
        elem.height = newItem.h;

        let onChange = this.props.onWidgetsChange;
        if(onChange)
            onChange(newWidgets);
    };

    onLayoutChange(layout: Layout[]) {
        let onChange = this.props.onLayoutChange;

        if(onChange)
            onChange(layout);
    }

    removeWidget(key: string){
        let newWidgets = new Map(this.props.widgets);
        newWidgets.delete(key);
        let onChange = this.props.onWidgetsChange;
        if(onChange)
            onChange(newWidgets);
    }

    render() {
        let widgets: JSX.Element[] = [];
        let editMode = this.props.editMode;
        this.props.widgets.forEach((w, k) => {
            let factory = this.state.factories.get(w.type);
            if(!factory)
                if(window.confirm("Could not load widget in stored layout, would you like to remove it?\nYou might have to spam yes for a while...\n\nPro-tip: Use ENTER")){
                    setTimeout(() => this.removeWidget(k), 0);
                    return;
                }
                else throw new Error(`Could not find factory for component '${w.type}'.`);
            let type = factory.getComponentType();
            let width = w.width ? w.width : type.DefaultWidth;
            let height = w.height ? w.height : type.DefaultHeight;
            let x = w.x ? w.x : 0;
            let y = w.y ? w.y : 0;

            widgets.push(
                <div key={k} data-grid={{x: x, y: y, w: width, h: height, minW: type.MinWidth, maxW: type.MaxWidth, minH: type.MinHeight, maxH: type.MaxHeight}}>
                    {factory.createElement({width: width, height: height, editMode: editMode, onDeletePressed: () => this.removeWidget(k)})}
                </div>);
        });

        let layouts = this.props.layout ? {normal: this.props.layout, small: this.props.layout} : undefined;

        return (
            (<ResponsiveGridLayout className="layout"
                            layouts={layouts}
                            rowHeight={50}
                            margin={[20,20]}
                            compactType={null}
                            isDraggable={editMode}
                            isResizable={editMode}
                            onResize={this.onResize.bind(this)}
                            onLayoutChange={this.onLayoutChange.bind(this)}
                            breakpoints={{normal: 1200, small: 996}}
                            cols={{normal: 12, small: 10}}>
                { widgets }
            </ResponsiveGridLayout>)


/*   old yucky grid
         <div>
                <ResponsiveGridLayout
                    className="layout"
                    cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                    rowHeight={30}>
                    <div key="c" data-grid={{x: 4, y: 0, w: 2, h: 10, minW: 2, minH: 10}}></div>
                    <div key="b" data-grid={{x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4}} id={"blueBox"}>b</div>
                    <div key="3" data-grid={{ w: 2, h: 3, x: 4, y: 0, minW: 2, minH: 3 }}>
                        <span className="text">3</span>
                    </div>
                    <div key="4" data-grid={{ w: 2, h: 3, x: 6, y: 0, minW: 2, minH: 3 }}>
                        <span className="text">4</span>
                    </div>
                    <div key="5" data-grid={{ w: 2, h: 3, x: 8, y: 0, minW: 2, minH: 3 }}>
                        <span className="text">5</span>
                    </div>
                </ResponsiveGridLayout>
            </div>*/
        );
    }
    
    static defaultProps = {editMode: false, layout: undefined, onLayoutChange: undefined, onWidgetsChange: undefined }
}


export default WidgetGrid;
import { ChangeEvent, Component } from "react";

type ImportModalProps = {close: () => void, onSubmit: (str: string) => void};

export class ImportModal extends Component<ImportModalProps, {text: string}>{
    constructor(props: ImportModalProps) {
        super(props);
        this.state = {text: ""};
    }
    
    onTextChange(evt: ChangeEvent<HTMLTextAreaElement>) {
        this.setState({text: evt.target.value});
    }
    
    render(){
        return (<div className="modal">
            <button className="close" onClick={this.props.close}>
            &times;
            </button>
            <div className="header"> Modal Title </div>
            <div className="content">
                <textarea value={this.state.text} onChange={this.onTextChange.bind(this)}/>
            </div>
            <div className="actions">
            <button
                className="button"
                onClick={() => {
                this.props.onSubmit(this.state.text);
                this.props.close();
                }}
            >
                Import
            </button>
            </div>
        </div>);
    };
}

export default ImportModal;
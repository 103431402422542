import React, { Component } from "react";
import WidgetDrawer from "./layout/WidgetDrawer";
import WidgetGrid, { WidgetGridEntry } from "./layout/WidgetGrid";
import ScheduleComponent, { ScheduleComponentFactory } from "./widgets/ScheduleComponent";
import HandinComponent, { HandinComponentFactory } from "./widgets/HandinComponent";
import StartingSoonComponent, {StartingSoonComponentFactory} from "./widgets/StartingSoonComponent";
import Switch from 'react-switch';
import { Layout } from "react-grid-layout";
import WidgetFactory from "./widgets/WidgetFactory";
import { WidgetType } from "./widgets/WidgetComponent";
import Popup from "reactjs-popup";
import ImportModal from "./ImportModal";
import AnnouncementComponent, {AnnouncementComponentFactory} from "./widgets/AnnouncementComponent";
import "./FrontPage.scss";
import TKLoebeseddelComponent, {TKLoebeseddelComponentFactory} from "./widgets/TKLoebeseddelComponent";

type FrontPageState = {
    editModeEnabled: boolean,
    factories: WidgetFactory[],
    widgets: Map<string, WidgetGridEntry>,
    layout?: Layout[]
};

type ConfigEntries = {key: string, value: WidgetGridEntry}[];

class Configuration {
    widgets: ConfigEntries;

    isConfigEntries(widgets: Map<string, WidgetGridEntry> | ConfigEntries): widgets is ConfigEntries {
        if((widgets as any).length)
            return true;
        return false;
    }

    constructor(widgets: Map<string, WidgetGridEntry> | ConfigEntries){
        if(this.isConfigEntries(widgets)){
            this.widgets = widgets;
        }
        else {
            this.widgets = [];
            widgets.forEach((v,k) => this.widgets.push({key: k, value: v}));
        }
    }

    getWidgets(): Map<string, WidgetGridEntry>{
        let map = new Map();
        this.widgets.forEach(({key: k, value: v}) => map.set(k, v));
        return map;
    }
}

class FrontPage extends Component<{}, FrontPageState> {
    constructor() {
        super({});

        let scheduleFactory = new ScheduleComponentFactory();
        let handinFactory = new HandinComponentFactory();
        let startingSoonFactory = new StartingSoonComponentFactory();
        let announcementFactory = new AnnouncementComponentFactory();
        let TKLoebeseddelFactory = new TKLoebeseddelComponentFactory();

        let factories = [scheduleFactory, handinFactory, startingSoonFactory, announcementFactory, TKLoebeseddelFactory];

        let widgets = this.getDefaultWidgets();

        let config = localStorage.getItem("config");
        if(config)
            widgets = this.loadFromString(config).widgets;

        this.state = {editModeEnabled: false, factories: factories, widgets: widgets, layout: []};

    }

    getDefaultWidgets(){
        let widgets = new Map();

        widgets.set("0", {type: ScheduleComponent.WidgetTypeID, x: 0, y: 0, width: 2, height: 9});
        widgets.set("1", {type: ScheduleComponent.WidgetTypeID, x: 4, y: 0, width: 6, height: 8});
        widgets.set("2", {type: HandinComponent.WidgetTypeID, x: 6, y: 9, width: 4, height: 4});
        widgets.set("3", {type: StartingSoonComponent.WidgetTypeID, x: 0, y: 10, width: 2, height: 3})
        widgets.set("4", {type: AnnouncementComponent.WidgetTypeID, x:2, y:0, width: 2, height: 9})
        widgets.set("5", {type: TKLoebeseddelComponent.WidgetTypeID, x:2, y:8})

        return widgets;
    }

    handleChange(checked: boolean) {
        this.setState({editModeEnabled: checked});
    }

    onLayoutChange(layout: Layout[]) {
        this.setState({layout: layout});
        this.updateLocalStorage(this.state.widgets, layout);
    }

    onWidgetsChange(widgets: Map<string, WidgetGridEntry>){
        this.setState({widgets: widgets});
        this.updateLocalStorage(widgets, this.state.layout);
    }

    onAddClicked(type: WidgetType) {
        this.state.widgets.set(Date.now().toString(), {type: type.WidgetTypeID});
        this.setState({widgets: this.state.widgets});
        this.updateLocalStorage(this.state.widgets, this.state.layout);
    }

    loadFromString(str: string) {
        let data = JSON.parse(str);
        let config = new Configuration(data.widgets);
        return this.loadFromConfig(config);
    }

    loadFromConfig(config: Configuration) {
        let state = {widgets: config.getWidgets(), layout: undefined};
        return state;
    }

    fixPos(widgets: Map<string, WidgetGridEntry>, layout?: Layout[]){
        if(!layout)
            return;

        for(let i = 0; i < layout.length; i++){
            let widget = widgets.get(layout[i].i);
            if(!widget)
                continue;
            widget.x = layout[i].x;
            widget.y = layout[i].y;
        }
        this.setState({layout: layout, widgets: widgets});
    }

    updateLocalStorage(widgets: Map<string, WidgetGridEntry>, layout?: Layout[]){
        this.fixPos(widgets, layout);
        localStorage.setItem("config", JSON.stringify(new Configuration(this.state.widgets)));
    }

    reset(){
        let widgets = this.getDefaultWidgets();
        let layout = undefined;
        this.setState({widgets: widgets, layout: layout});
        this.updateLocalStorage(widgets, layout);
    }

    render() {

        return (
            <div className="frontPage">
                <div className="editModeHeader">
                    <div className="editMode">
                        <span>Edit mode</span>
                        <Switch checked={this.state.editModeEnabled} onChange={this.handleChange.bind(this)}/>
                    </div>
                    <div className="debugButtons">
                        <button onClick={this.reset.bind(this)}>Reset</button>
                        <Popup
                            trigger={<button className="button"> Import </button>}
                            modal
                            nested
                        >
                            {(close: () => void)  =>  (
                                <ImportModal close={close} onSubmit={str => this.setState(this.loadFromString(str))}/>
                                )}
                        </Popup>
                        <button onClick={() => window.alert(JSON.stringify(new Configuration(this.state.widgets)))}>Export</button>
                    </div>
                </div>
                <div className="gridContainer">
                    <WidgetGrid widgets={this.state.widgets} factories={this.state.factories} layout={this.state.layout} editMode={this.state.editModeEnabled} onLayoutChange={this.onLayoutChange.bind(this)} onWidgetsChange={this.onWidgetsChange.bind(this)}/>
                </div>
                <WidgetDrawer active={this.state.editModeEnabled} factories={this.state.factories} onAddClicked={this.onAddClicked.bind(this)}/>
            </div>
        );
    }
}

export default FrontPage;
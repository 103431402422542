import WidgetComponent, {WidgetElement, WidgetProps, WidgetType} from "./WidgetComponent";
import React, {Component} from "react";
import WidgetFactory, {FactoryInput, PreviewInfo} from "./WidgetFactory";
import "./TKLoebeseddelComponent.scss"

class TKLoebeseddelComponent extends Component<WidgetProps> {
    static WidgetTypeID: string = "tk-loebeseddel";
    static DefaultTitle: string = "Dagens TK Løbeseddel";
    static DefaultExtraClass: string = "tkLoebeseddelWidget";
    static DefaultWidth: number = 4;
    static DefaultHeight: number = 6;
    static MinWidth: number = 2;
    static MaxWidth: number = Infinity;
    static MinHeight: number = 3;
    static MaxHeight: number = Infinity;

    /*
    * TODO: In the future, this should probably also have a data strategy.
    * */

    render() {
        let props = this.props;
        return <WidgetComponent {...props} onSettingsChanged={() => {this.forceUpdate.bind(this); this.forceUpdate()}}>
            <div className={"TKLoebeseddelContent"}>
                <img src={"https://taagekammeret.dk/media/__sized__/2020/lobesedler/060521-thumbnail-2280x2280.png"} alt={"Dagens loebeseddel"}/>
            </div>
        </WidgetComponent>
    }

    static defaultProps = (() => {
        let props = WidgetComponent.generateDefaultProps(TKLoebeseddelComponent);
        return props;
    })();

    getHeight(){ return this.props.height; }
    getWidth(){ return this.props.width; }

}

export class TKLoebeseddelComponentFactory implements WidgetFactory {
    createElement(props: FactoryInput): WidgetElement {
        return (<TKLoebeseddelComponent {...props}/>);
    }

    getComponentType(): WidgetType {
        return TKLoebeseddelComponent;
    }

    getPreviewInfo(): PreviewInfo {
        return {preview: (<TKLoebeseddelComponent width={4} height={2}/>), displayName: TKLoebeseddelComponent.DefaultTitle };
    }

}

export default TKLoebeseddelComponent;
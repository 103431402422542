import {Setting, settingType} from './WidgetComponent'

let ScheduleSettings: Array<Setting>;

ScheduleSettings = [
    {name: 'Test Indstilling!',
        type: settingType.toggle,
        currentValue: true }
]

export default ScheduleSettings;
import React, { Component } from "react";
import IAnnouncementDataStrategy from "../data/IAnnouncementDataStrategy";
import { MockAnnouncementDataStrategy } from "../data/MockAnnouncementDataStrategy";
import PopupWindow from "./PopupWindow";
import WidgetComponent, { WidgetProps } from "./WidgetComponent";
import WidgetFactory, { FactoryInput } from "./WidgetFactory";
import './AnnouncementComponent.scss';
export type Announcement = {
    title: string,
    content: string,
    course: string,
    author: string,
    date: Date,
}

export type AnnouncementProps = WidgetProps & {
    announcements : Announcement[],
};


class AnnouncementComponent extends Component<AnnouncementProps> {
    static WidgetTypeID: string = "announcement"
    static DefaultTitle = "Announcements"
    static DefaultExtraClass: string = "announcementWidget";
    static DefaultWidth: number = 4;
    static DefaultHeight: number = 4;
    static MinWidth: number = 1;
    static MaxWidth: number = Infinity;
    static MinHeight: number = 3;
    static MaxHeight: number = Infinity;

    render() {


        // Convert to react elements
        const announcements = this.props.announcements.map(a =>
            <div className={"announcement"} onClick={() => PopupWindow.showpopup(a.content)}>
                <p className={"announcementHeader"}>{a.date.toLocaleString("da-DK", {
                    hour: "numeric",
                    minute: 'numeric',}).replace('.', ':')} - {a.course}</p>
                <p className={"announcementContent"}>{a.content}</p>

            </div>)


        let props = this.props;
        return (
            <WidgetComponent {...props} settings={this.props.settings} onSettingsChanged={() => {this.forceUpdate.bind(this); this.forceUpdate()}}>
              {announcements}
            </WidgetComponent>  
        );
    }

    static defaultProps = (() => {
        let props = WidgetComponent.generateDefaultProps(AnnouncementComponent) as AnnouncementProps;
        // TODO: add settings
        props.settings = [];
        return props;
    })();
}

export class AnnouncementComponentFactory implements WidgetFactory {
    announcementData: IAnnouncementDataStrategy;

    constructor() {
        this.announcementData = new MockAnnouncementDataStrategy();
    }

    getComponentType() {
        return AnnouncementComponent;
    }

    createPreview() {
        return (<AnnouncementComponent announcements={this.announcementData.getData()} width={2} height={2}/>)
    }

    createElement(params: FactoryInput) {
        return (<AnnouncementComponent announcements={this.announcementData.getData()} {...params}/>)
    }

    getPreviewInfo() {
        return {preview: (<AnnouncementComponent announcements={this.announcementData.getData()} width={2} height={2}/>), displayName: AnnouncementComponent.DefaultTitle};
    }
}


export default AnnouncementComponent;
import IAnnouncementDataStrategy from "./IAnnouncementDataStrategy";
import {Announcement} from "../widgets/AnnouncementComponent";

export class MockAnnouncementDataStrategy implements IAnnouncementDataStrategy {
    getData(): Announcement[] {
        return [{
            title: "Title test",
            course: "Test course",
            content: "Content test",
            author: "Author test",
            date: new Date(2019, 1, 1, 10, 30, 14),
        },{
            title: "Title test 2",
            course: "Test course 2",
            content: "Content test 2",
            author: "Author test 2",
            date: new Date(1970, 0, 0),
        },{
            title: "Title test 3",
            course: "Test course 3",
            content: "Content test 3",
            author: "Author test 3",
            date: new Date(1970, 0, 0),
        },{
            title: "Assignment 2: what \"same\" means",
            course: "Database systems",
            content: "It came to my attention that some students have been asking what the word \"same\" means in the expression \"beverages of the same name\". It is natural that the expression \"same\" means \"same\", therefore the expression \"beverages of the same name\" denotes beverages of the same name. Any assumption that the word \"same\" means \"same or different\" is wrong and cannot be taken as correct.",
            author: "Panagiotis Karras",
            date: new Date(2020, 1, 21),
        },
        ];
    }
}
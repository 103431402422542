import React, {Component} from 'react';
import Popup from 'reactjs-popup';
import WidgetComponent, {WidgetProps} from './WidgetComponent';
import './ScheduleComponent.scss';
import IWidget from "./IWidget";
import WidgetFactory, { FactoryInput } from './WidgetFactory';
import IScheduleDataStrategy from '../data/IScheduleDataStrategy';
import MockScheduleDataStrategy from '../data/MockScheduleDataStrategy';
import ScheduleSettings from "./ScheduleSettings";

export type ScheduleEntry = {
    startTime: Date,
    endTime?: Date,
    courseName: string,
    activity: string,
    zoom: string,
    location: string,
    pdf?: string
};

export type Schedule = Array<ScheduleEntry>;

export type ScheduleProps = WidgetProps & {
    schedule: Schedule,
};

class ScheduleEntryComponent extends Component<{entry: ScheduleEntry}> {
    render() {
        let entry = this.props.entry;

        let content = (
            <div className="scheduleEntry">
                <div className="scheduleEntryLeft">
                    <div className="scheduleEntryCourse">{entry.courseName}</div>
                    <div className="scheduleEntryActivity">{entry.activity}</div>
                </div>
                <div className="scheduleEntryRight">
                    <div className="scheduleEntryTimeStart">{entry.startTime.toLocaleTimeString("da-DK", {hour: "2-digit", minute: "2-digit"})}</div>
                    <div className="scheduleEntryTimeEnd">{entry.endTime ? entry.endTime.toLocaleTimeString("da-DK", {hour: "2-digit", minute: "2-digit"}) : ""}</div>
                </div>
            </div>
        );

        return (
            <Popup trigger={() => content} position="right center" closeOnDocumentClick>Test</Popup>
        );
    }
}

class ScheduleListGroup extends Component<{header: string, entries: ScheduleEntry[]}> {
    createList(schedule: Schedule) {
        return schedule.map(entry => {
            return <ScheduleEntryComponent entry={entry}/>
        });
    }
    render() {
        return (
            <div className="scheduleListGroup">
                <p className="scheduleListGroupHeader">{this.props.header}</p>
                {this.createList(this.props.entries)}
            </div>
        );
    }
}

function createDayList(schedule: Schedule): [Date, ScheduleEntry[]][] {
    let days: [Date, ScheduleEntry[]][] = [];

    function schedDate(e: ScheduleEntry): Date {
        return new Date(e.startTime.getFullYear(), e.startTime.getMonth(), e.startTime.getDate());
    }

    let curIndex: number = 0;

    schedule.forEach(e => {
        let date = schedDate(e);
        if(days.length === 0){
            days.push([date, [e]]);
            curIndex = 0;
        }
        else {
            if(days[curIndex][0].getTime() === date.getTime()){
                days[curIndex][1].push(e);
            }
            else {
                days.push([date, [e]]);
                curIndex++;
            }
        }
    });
    return days;
}

class ScheduleList extends Component<{schedule: Schedule}> {
    createList(schedule: Schedule) {
        let elems = createDayList(schedule).map(test => {
            let [date, entries] = test;
            return <ScheduleListGroup header={`${date.getDate()}/${date.getMonth()+1}-${date.getFullYear()}`} entries={entries}/>
        });

        return elems;
    }

    render() {
        return this.createList(this.props.schedule);
    }
}

class ScheduleWeekView extends Component<{schedule: Schedule}, {startDate: Date}> {
    constructor(props: {schedule: Schedule}) {
        super(props);

        let startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        startDate.setDate(startDate.getDate() - (startDate.getDay() + 6) % 7);
        startDate = new Date(2021, 4, 5, 0, 0, 0);
        this.state = {startDate: startDate};
    }

    positionInGrid(date0: Date, startTime: Date, endTime?: Date) {
        let c = Math.floor((startTime.getTime() - date0.getTime()) / 1000 / 60 / 60 / 24) + 1;
        let r1 = startTime.getHours() - 6;
        let r2 = endTime ? endTime?.getHours() - 6 : r1;
        return {c: c, r1: r1, r2: r2}
    }

    render() {
        let startDate = this.state.startDate;
        let elems = this.props.schedule.map(entry => {
            let position = this.positionInGrid(startDate, entry.startTime, entry.endTime);
            return Object.assign(position, {entry: entry});
        }).filter(e => {
            return e.c >= 1 && e.c < 6 && e.r1 >= 1 && e.r1 < 10;
        }).map(e => {
            return <div style={{gridColumn: e.c, gridRowStart:e.r1, gridRowEnd:e.r2}}><ScheduleEntryComponent entry={e.entry}/></div>
        });

        let dates = [];
        for (let i = 0; i < 5; i++) {
            let date = new Date(startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate() + i);
            dates.push(<div style={{gridColumn: i+1, gridRow: 1}} className="scheduleWeekDate">{`${date.getDate()}/${date.getMonth()}`}</div>);
        }

        let lineTime = new Date(2021, 4, 7, 13, 12);
        let linePos = this.positionInGrid(startDate, new Date(2021, 4, 7, 13, 12));
        let line = <div style={{gridColumn: linePos.c, gridRowStart: linePos.r1, gridRowEnd: linePos.r1 + 1, height: `${lineTime.getMinutes() / 0.6}%`}} className="scheduleWeekLine">Skal ikke være tom</div>

        return (
            <div className="scheduleWeekGrid">
                {dates}
                {elems}
                {line}
            </div>
        );
    }
}

class ScheduleComponent extends Component<ScheduleProps> implements IWidget {
    static WidgetTypeID: string = "schedule";
    static DefaultTitle: string = "Skema";
    static DefaultExtraClass: string = "scheduleWidget";
    static DefaultWidth: number = 2;
    static DefaultHeight: number = 4;
    static MinWidth: number = 1;
    static MaxWidth: number = Infinity;
    static MinHeight: number = 3;
    static MaxHeight: number = Infinity;

    render() {
        let props = this.props;
        //let components = this.createList(this.props.schedule);
        return (
            <WidgetComponent {...props} settings={props.settings} onSettingsChanged={() => {this.forceUpdate.bind(this); this.forceUpdate()}}>
                {props.width <= 3 || props.height < 5 ? <ScheduleList schedule={this.props.schedule}/> : <ScheduleWeekView schedule={this.props.schedule}/>}
            </WidgetComponent>
        );
    }

    static defaultProps = (() => {
        let props = WidgetComponent.generateDefaultProps(ScheduleComponent) as ScheduleProps;
        props.schedule = [];
        props.settings = ScheduleSettings;
        return props;
    })();

    getHeight(){ return this.props.height; }
    getWidth(){ return this.props.width; }
}

export class ScheduleComponentFactory implements WidgetFactory {
    scheduleData: IScheduleDataStrategy;

    constructor() {
        this.scheduleData = new MockScheduleDataStrategy();
    }

    getComponentType() {
        return ScheduleComponent;
    }

    getPreviewInfo() {
        return {preview: (<ScheduleComponent schedule={this.scheduleData.getData()} width={2} height={2}/>), displayName: ScheduleComponent.DefaultTitle }
    }

    createElement(params: FactoryInput) {
        return <ScheduleComponent schedule={this.scheduleData.getData()} {...params}/>;
    }
}


export default ScheduleComponent;
import {Setting, settingType} from './WidgetComponent'

let HandinSettings: Array<Setting>;

export enum HandinSettingIndex{
    visAfleveredeAfleveringer,
    visAfleveringerMedFeedback,
    sorterEfter
}

HandinSettings = [
    {name: 'Vis afleverede afleveringer',
        type: settingType.toggle,
        currentValue: true },
    {name: 'Vis afleveringer med feedback',
        type: settingType.toggle,
        currentValue: true },
    {name: 'Sorter efter:',
        type: settingType.dropdown,
        currentValue: "Afleveringsfrist",
        values: ["Afleveringsfrist", "Mulighed 2"]}
]

export default HandinSettings;
import React, {ChangeEvent, Component, JSXElementConstructor, ReactElement} from 'react';
import './WidgetComponent.scss';
import { renderIcon } from '../icons';

export enum settingType {
    toggle,
    dropdown
}

export type Setting = {
    name: string,
    type: settingType,
    currentValue: any,
    values?: any[]
}

export type WidgetProps = {
    title: string,
    width: number,
    height: number,
    extraClass?: string,
    settings: Setting[],
    onSettingsChanged: () => void
    editMode: boolean,
    onDeletePressed?: () => void
};

type WidgetState = {
    isChangingSettings: boolean
}

export type WidgetElement = Omit<ReactElement, "props" | "type"> & {
    props: WidgetProps,
    type: WidgetType
};

export type WidgetType = JSXElementConstructor<any> & {
    WidgetTypeID: string,
    DefaultTitle: string,
    DefaultExtraClass: string,
    DefaultWidth: number,
    DefaultHeight: number,
    MinWidth: number,
    MaxWidth: number,
    MinHeight: number,
    MaxHeight: number,
};

export class WidgetComponent extends Component<WidgetProps, WidgetState> {
    constructor(props: WidgetProps) {
        super(props);

        this.state = {
            isChangingSettings: false
        };

    }

    clickedSettingsButton(){
        this.setState(() => ({isChangingSettings: !this.state.isChangingSettings}))
        this.props.onSettingsChanged();
    }

    getSettingsPanel(){
        function toggle(this: Setting, e: ChangeEvent<HTMLInputElement>): void {
            this.currentValue = e.target.checked
        }
        function select(this: Setting, e: ChangeEvent<HTMLSelectElement>): void{
            this.currentValue = e.target.value
        }


        return <div className={"settingsPanel"}>
            {this.props.settings.map((s) => {
                if (s.type === settingType.toggle){
                    return <div className={"checkboxContainer"}>
                        <input type={"checkbox"} className={"checkbox"} id={s.name} defaultChecked={s.currentValue} onChange={toggle.bind(s)}/>
                        <label htmlFor={s.name}>{s.name}</label>
                    </div>
                } else if(s.type === settingType.dropdown){
                    return <div className={"selectContainer"}>
                        <label htmlFor={s.name}>{s.name}</label>
                        <select name={s.name} id={s.name} onChange={select.bind(s)}>
                            {s.values?.map(v => <option value={v} selected={s.currentValue === v}>{v}</option>)}
                        </select>
                    </div>
                } else{
                    return <div>Setting layout not implemented for type {s.type} of settingsType on setting {s.name}</div>
                }
            })}
        </div>
    }



    render() {
        let className = "widget " + this.props.extraClass;

        if(this.props.extraClass)
            className += ` ${this.props.extraClass}`;

        if(this.props.editMode)
            className += " editWidget";
            return (
                <div className={className}>

                    <div className="widgetHeader">
                        <div className="widgetTitle">{this.props.title}</div>
                        <button className={`widgetSettingsButton ${this.state.isChangingSettings ? "changingSettings" : ""}`} onClick={this.clickedSettingsButton.bind(this)}>{renderIcon('cog')}</button>
                    {this.props.editMode ? <button className="widgetDeleteButton" onClick={this.props.onDeletePressed}>{renderIcon('exit')}</button> : null}
                    </div>
                    {this.state.isChangingSettings ?
                        this.getSettingsPanel() : (<div className="widgetContent">{this.props.children}</div>)
                    }
                </div>
            );
        //<p style={{fontSize: 10}}>I am {this.props.width} wide</p>
    }

    static generateDefaultProps(widgetType: WidgetType): WidgetProps {
        return {
            title: widgetType.DefaultTitle,
            extraClass: widgetType.DefaultExtraClass,
            width: widgetType.DefaultWidth,
            height: widgetType.DefaultHeight,
            settings: [],
            onSettingsChanged: () => {},
            editMode: false,
        };
    }

    static defaultProps = { title: "", extraClass: undefined, width: 0, height: 0};
}


export default WidgetComponent;
export function renderIcon(name:String) {
  switch (name) {

    case 'cog':
      return (
        <div className="Icon Icon--cog">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
            <path d="M13,5.66l-.07,0L12.66,5l0-.07c.85-1.95.79-2,.65-2.15L12.21,1.61A.26.26,0,0,0,12,1.53a6.93,6.93,0,0,0-1.92.77l-.07,0-.68-.28,0-.07c-.78-2-.86-2-1.06-2H6.67c-.19,0-.28,0-1,2l0,.07L5,2.34l-.06,0a9.8,9.8,0,0,0-2-.73.27.27,0,0,0-.19.08L1.61,2.78c-.14.15-.2.21.69,2.12l0,.07-.28.68L2,5.68c-2,.78-2,.85-2,1.06V8.33c0,.2,0,.29,2,1l.07,0,.28.68,0,.06c-.85,2-.79,2-.65,2.16l1.12,1.12a.3.3,0,0,0,.2.08A7.09,7.09,0,0,0,4.9,12.7l.07,0,.68.28,0,.07c.78,2,.86,2,1.06,2H8.33c.2,0,.28,0,1-2l0-.07.69-.29.06,0a10.09,10.09,0,0,0,2,.73.28.28,0,0,0,.2-.08l1.13-1.12c.14-.15.2-.21-.69-2.12l0-.07L13,9.35l.06,0c2-.79,2-.87,2-1.07V6.67C15,6.47,15,6.38,13,5.66ZM7.5,10.08A2.58,2.58,0,1,1,10.08,7.5,2.59,2.59,0,0,1,7.5,10.08Z"/>
          </svg>
        </div>
      );

    case 'checkmark':
      return (
        <div className="Icon Icon--checkmark">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
            <path d="M1.38,5.41a.6.6,0,0,1,.86,0h0L5.93,9.07l6.84-6.84a.63.63,0,0,1,.87,0l1.18,1.18a.63.63,0,0,1,0,.88L7.55,11.57h0l-1.2,1.2a.65.65,0,0,1-.88,0L.18,7.49a.63.63,0,0,1,0-.88Z"/>
          </svg>
        </div>
      );

    case 'exit':
      return (
        <div className="Icon Icon--exit">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
            <path d="M12.77,10.71l-1.2-1.2h0l-2-2,.29-.28.44-.44h0l2.49-2.49a.63.63,0,0,0,0-.88l-.12-.12-1.06-1h0a.63.63,0,0,0-.88,0l-1.2,1.2h0l-2,2-.28-.29-.44-.44h0L4.29,2.23a.63.63,0,0,0-.88,0l-.12.12-1,1.06h0a.63.63,0,0,0,0,.88l1.2,1.2h0l2,2-.29.28-.44.44h0L2.23,10.71a.63.63,0,0,0,0,.88l.12.12,1.06,1h0a.63.63,0,0,0,.88,0l1.2-1.2h0l2-2,.28.29.44.44h0l2.49,2.49a.63.63,0,0,0,.88,0l.12-.12,1-1.06h0A.63.63,0,0,0,12.77,10.71Z"/>
          </svg>
        </div>
      );

    default:
      return "No icon named " + name;
  }
}
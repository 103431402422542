import React, {Component} from 'react';
import WidgetComponent, {WidgetProps} from './WidgetComponent';
import './HandinComponent.scss';
import PopupWindow from "./PopupWindow";
import WidgetFactory, { FactoryInput } from './WidgetFactory';
import IHandinDataStrategy from '../data/IHandinDataStrategy';
import { MockHandinDataStrategy } from '../data/MockHandinDataStrategy';
import { renderIcon } from '../icons';
import HandinSettings, {HandinSettingIndex} from "./HandinSettings";

export type HandinProps = WidgetProps & {
    handins: Handin[],
};

const dueSoonThreshold = 14;

export type Handin = {
    name: string,
    course: string,
    isFinished: boolean,
    isReHandin?: boolean,
    dueDate?: Date,
}

type finishedHandin = {
    name: string,
    course: string,
    dueDate: Date,
}

class HandinComponent extends Component<HandinProps> {
    static WidgetTypeID: string = "handin";
    static DefaultTitle: string = "Afleveringer";
    static DefaultExtraClass: string = "handinWidget";
    static DefaultWidth: number = 3;
    static DefaultHeight: number = 4;
    static MinWidth: number = 2;
    static MaxWidth: number = Infinity;
    static MinHeight: number = 3;
    static MaxHeight: number = Infinity;

    render() {
        function datediff(first: Date, second: Date) {
            // Take the difference between the dates and divide by milliseconds per day.
            // Round to nearest whole number to deal with DST.
            return Math.round(Math.abs((second.getTime()-first.getTime()))/(1000*60*60*24));
        }


        // Convert to react elements
        const finishedHandins = this.props.handins.filter(h => h.isFinished).map(h =>
            <div className={"handin"} onClick={() => PopupWindow.showpopup(h.name)}>
                <div className={"textLeft"}>
                    <p className={"handinName"}>{h.name}</p>
                    <p className={"courseName subdued"}>{h.course}</p>
                </div>
                <div className={h.isReHandin ? "doneStatus failed" : "doneStatus accepted"}>{h.isReHandin ? renderIcon('exit') : renderIcon('checkmark')}</div>
            </div>)

        const unfinishedHandins = this.props.handins.filter(h => !h.isFinished).map(h => h as finishedHandin).map(h => {
            let isDueSoon: boolean = datediff(new Date(), h.dueDate) <= dueSoonThreshold;
            return (<div className={isDueSoon ? "dueSoon handin" : "dueLater handin"} onClick={() => PopupWindow.showpopup(h.name)}>
                <div className={"textLeft"}>
                    <p className={"handinName"}>{h.name}</p>
                    <p className={"courseName subdued"}>{h.course}</p>
                </div>
                <span className={"daysDue"}>{datediff(new Date(), h.dueDate)} dage</span>
            </div>)
        })

        let props = this.props;
        return (
            <WidgetComponent {...props} settings={this.props.settings} onSettingsChanged={() => {this.forceUpdate.bind(this); this.forceUpdate()}}>
                {unfinishedHandins}
                { props.settings[HandinSettingIndex.visAfleveredeAfleveringer].currentValue  ? (
                    <div>
                        <div className="divider"/>
                        {finishedHandins}
                    </div>) : ""}
            </WidgetComponent>
        );
    }

    static defaultProps = (() => {
        let props = WidgetComponent.generateDefaultProps(HandinComponent) as HandinProps;
        props.settings = HandinSettings;
        return props;
    })();
}

export class HandinComponentFactory implements WidgetFactory {
    handinData: IHandinDataStrategy;

    constructor() {
        this.handinData = new MockHandinDataStrategy();
    }

    getComponentType() {
        return HandinComponent;
    }

    getPreviewInfo() {
        return {preview: (<HandinComponent handins={this.handinData.getData()} width={2} height={2}/>), displayName: HandinComponent.DefaultTitle};
    }

    createElement(params: FactoryInput) {
        return (<HandinComponent handins={this.handinData.getData()} {...params}/>)
    }
}


export default HandinComponent;
import { Component } from 'react';
import { WidgetType } from '../widgets/WidgetComponent';
import WidgetFactory, { PreviewInfo } from '../widgets/WidgetFactory';
import './WidgetDrawer.scss';


type WidgetDrawerProps = {active: boolean, factories: WidgetFactory[], onAddClicked: (type: WidgetType) => void }
type WidgetDrawerState = { }

class WidgetDrawer extends Component<WidgetDrawerProps, WidgetDrawerState> {
     render() {
        let activeClass = this.props.active ? "widgetDrawerActive" : "widgetDrawerInactive";

        return (
             <div className={`widgetDrawerContainer ${activeClass}`}>
               <div className={`widgetDrawer ${activeClass}`}>
                    {this.props.factories.map(f => <WidgetDrawerElement info={f.getPreviewInfo()} onClick={() => this.props.onAddClicked(f.getComponentType())}/>)}
               </div>
             </div>
        );
     }
}

class WidgetDrawerElement extends Component<{info: PreviewInfo, onClick: () => void}> {
     render() {
          return (
               <div className="widgetDrawerElement">
                    <div className="widgetDrawerElementPreview">
                         <div className="widgetDrawerElementPreviewIcon">
                              {this.props.info.displayName.charAt(0)}
                         </div>
                    </div>
                    <div className="widgetDrawerElementTitle">
                         {this.props.info.displayName}
                    </div>
                    <button onClick={this.props.onClick}>+</button>
               </div>
          );
     }
}

export default WidgetDrawer;
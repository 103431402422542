import WidgetComponent, {WidgetElement, WidgetProps, WidgetType} from "./WidgetComponent";
import {HandinProps} from "./HandinComponent";
import {ScheduleEntry, ScheduleProps} from "./ScheduleComponent";
import React, {Component} from "react";
import WidgetFactory, {FactoryInput, PreviewInfo} from "./WidgetFactory";
import IHandinDataStrategy from "../data/IHandinDataStrategy";
import IScheduleDataStrategy from "../data/IScheduleDataStrategy";
import {MockHandinDataStrategy} from "../data/MockHandinDataStrategy";
import MockScheduleDataStrategy from "../data/MockScheduleDataStrategy";
import StartingSoonSettings from "./StartingSoonSettings";
import "./StartingSoonComponent.scss"

export type StartingSoonProps = WidgetProps & HandinProps & ScheduleProps;

class StartingSoonComponent extends Component<StartingSoonProps> {
    static WidgetTypeID: string = "starting-soon";
    static DefaultTitle: string = "Startende Snart";
    static DefaultExtraClass: string = "startingSoonWidget";
    static DefaultWidth: number = 2;
    static DefaultHeight: number = 3;
    static MinWidth: number = 2;
    static MaxWidth: number = Infinity;
    static MinHeight: number = 3;
    static MaxHeight: number = Infinity;

    getUpcomingScheduleEntry(): ScheduleEntry{
        let closestSchedule: ScheduleEntry = this.props.schedule[0];
        let currentDate = new Date();

        this.props.schedule.forEach((e) => {
            // @ts-ignore TODO: Do some refactoring of schedule.
            if (e.endTime > currentDate && e.startTime < closestSchedule?.startTime){
                closestSchedule = e;
            }
        })

        return closestSchedule;
    }

    render() {
        let props = this.props;

        let closestSchedule = this.getUpcomingScheduleEntry();

        return <WidgetComponent {...props} onSettingsChanged={() => {this.forceUpdate.bind(this); this.forceUpdate()}}>
            <div className={"startingSoonContent"}>
                <p className={"startingSoonHeader"}>{closestSchedule.activity} : {closestSchedule.courseName}</p>
                <p>{closestSchedule.startTime.getHours()} : {closestSchedule.startTime.getMinutes()} på <a href={`https://aarhusuniversity.zoom.us/j/${closestSchedule.zoom}`}>zoom</a> / <a href={"https://maps.google.com"}>{closestSchedule.location}</a></p>
                {
                    closestSchedule.pdf != null ? (<p className={"startingSoonExtra"}>Se extra informationer på <a href={closestSchedule.pdf}>pdf</a></p>) : ""

                }
            </div>
        </WidgetComponent>
    }

    static defaultProps = (() => {
        let props = WidgetComponent.generateDefaultProps(StartingSoonComponent) as ScheduleProps;
        props.schedule = [];
        props.settings = StartingSoonSettings;
        return props;
    })();

    getHeight(){ return this.props.height; }
    getWidth(){ return this.props.width; }

}

export class StartingSoonComponentFactory implements WidgetFactory {
    handinData: IHandinDataStrategy;
    scheduleData: IScheduleDataStrategy;

    constructor() {
        this.handinData = new MockHandinDataStrategy();
        this.scheduleData = new MockScheduleDataStrategy();
    }


    createElement(props: FactoryInput): WidgetElement {
        return (<StartingSoonComponent handins={this.handinData.getData()} schedule={this.scheduleData.getData()} {...props}/>);
    }

    getComponentType(): WidgetType {
        return StartingSoonComponent;
    }

    getPreviewInfo(): PreviewInfo {
        return {preview: (<StartingSoonComponent schedule={this.scheduleData.getData()} handins={this.handinData.getData()} width={2} height={2}/>), displayName: StartingSoonComponent.DefaultTitle };
    }

}










export default StartingSoonComponent;